
























































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import SearchBar from "@/components/search-bar.vue";
import AddVenueListItem from "@/components/venue-detail/AddVenueListItem.vue";
import VenueSubscriptionListItem from "@/components/venue-subscription/VenueSubscriptionListItem.vue";
import { mapActions, mapGetters } from "vuex";
import { VenueData, VenueSubscriptionData } from "@/types";
import { fillWithMockData, WINDOW_WIDTH } from "@/utils/helpers";

export default Vue.extend({
  name: "subscription-plan" as string,

  components: {
    VenueSubscriptionListItem,
    Layout,
    "search-bar": SearchBar,
    "venue-subscription-list-item": VenueSubscriptionListItem,
    "add-venue-list-item": AddVenueListItem,
  },

  data() {
    return {
      currentPage: 1,
      perPage: 10,
    };
  },
  async created(): Promise<void> {
    await this.checkLoggedUserRolePermission({ permissionId: 4 });
  },

  async mounted(): Promise<void> {
    await this.fetchVenues({ fetchRatings: true, name: "" });
    await this.setVenuesSubscription();
    await this.setSubscriptionProducts();
    this.fetchVenuesImages();
  },

  computed: {
    ...mapGetters("venueModule", {
      venues: "GET_VENUES",
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID",
      isCorporate: "GET_IS_CORPORATE",
      company: "GET_COMPANY",
    }),
    ...mapGetters("venueSubscriptionModule", {
      venuesSubscription: "GET_VENUES_SUBSCRIPTION",
      subscriptionProducts: "GET_ALL_SUBSCRIPTION_PRODUCTS",
    }),
    ...mapGetters("userModule", {
      hasCreateDeletePermission: "GET_CREATE_DELETE_VENUE_PERMISSION",
    }),
    totalVenues(): number {
      return this.venues.length;
    },
    venuesList(): Array<VenueData> {
      return this.venues.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },

  methods: {
    ...mapActions("venueModule", {
      fetchVenues: "FETCH_VENUES",
    }),
    ...mapActions("venueSubscriptionModule", {
      setVenuesSubscription: "FETCH_VENUES_SUBSCRIPTION",
      setSubscriptionProducts: "FETCH_SUBSCRIPTION_PRODUCTS",
    }),
    ...mapActions("globalModule", {
      setImage: "FETCH_ENTITY_IMAGE",
    }),
    ...mapActions("userModule", {
      checkLoggedUserRolePermission: "CHECK_LOGGED_USER_ROLE_PERMISSION",
    }),
    emitVenuesImages(): void {
      this.currentPage = 1;
      this.fetchVenuesImages();
    },
    goToAddNewVenue() {
      this.$router.push("/add-new-venue-step-1");
    },
    fetchVenuesImages(): void {
      for (let i = 0; i < this.venues.length; i++) {
        const venue: VenueData = this.venues[i];

        if (!!venue.image_last_updated) {
          this.setImage({
            id: venue.id,
            imageType: "clientzone_venue",
            multi: true,
          });
        }
      }
    },
    venueSubscription(venueId: number): VenueSubscriptionData {
      return this.venuesSubscription.find(s => s.venueId === venueId);
    },
  },

  watch: {
    companyId: {
      async handler(newValue: number, oldValue: number): Promise<void> {
        if (newValue != oldValue) {
          await this.fetchVenuesCount();
          await this.fetchVenuesPaginated({
            fetchRatings: false,
            name: "",
            currentPage: this.currentPage,
          });
          await this.setVenuesSubscription();
          this.fetchVenuesImages();
        }
      },
    },
  },
});
