





















































































































































































import Vue, { PropType } from "vue";
import {
  SubscriptionProductsData,
  VenueData,
  VenueSubscriptionData,
} from "@/types";

export default Vue.extend({
  name: "venue-list-item" as string,

  props: {
    venue: Object as PropType<VenueData>,
    venueSubscription: Object as PropType<VenueSubscriptionData>,
    subscriptionProducts: Array as PropType<Array<SubscriptionProductsData>>,
  },

  computed: {
    getSubscriptionType(): number {
      if (!this.venueSubscription) return -1;

      const product: SubscriptionProductsData = this.subscriptionProducts.find(
        p => p.id === this.venueSubscription.stripeProductWithPriceId
      );
      if (product) {
        return product.songorooPackageType;
      } else if (
        this.venueSubscription.isCorporate &&
        !this.venueSubscription.stripeProductWithPriceId
      ) {
        return 3;
      } else {
        return -1;
      }
    },
    subscriptionExpirationDate(): string {
      if (!this.venueSubscription) return "";

      const timestamp =
        Number(this.venueSubscription.subscriptionEndsAt) * 1000;
      const date = new Date(timestamp);

      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    },
    hasStreamingDevice(): boolean {
      if (!this.venueSubscription) return false;

      return this.venueSubscription.includedStreamingDevice;
    },
    hasAudioMessages(): boolean {
      if (!this.venueSubscription) return false;

      return !!this.venueSubscription.additionalServices;
    },
    isSubscriptionCanceled(): boolean {
      if (!this.venueSubscription) return false;

      return this.venueSubscription.cancelledAt != null;
    },
    isSubscriptionExpired(): boolean {
      if (!this.venueSubscription) return false;

      const currentDate = Math.floor(Date.now() / 1000);
      const expiredDate = Number(this.venueSubscription.subscriptionEndsAt);

      return (
        currentDate >= expiredDate ||
        this.venueSubscription.subscriptionStatus == "canceled"
      );
    },
    isSubscriptionIncomplete(): boolean {
      if (!this.venueSubscription) return false;

      return this.venueSubscription.subscriptionStatus == "incomplete";
    },
    isSubscriptionIncompleteExpired(): boolean {
      if (!this.venueSubscription) return false;

      return this.venueSubscription.subscriptionStatus == "incomplete_expired";
    },
    isSubscriptionExpiredOrIncomplete(): boolean {
      return this.isSubscriptionExpired || this.isSubscriptionIncomplete || this.isSubscriptionIncompleteExpired;
    },
    isTrial(): boolean {
      if (this.venueSubscription.subscriptionStatus === "trialing" ||
        this.venueSubscription.isTrial === true
      ) {
        return true;
      }

      return false;
    },
  },

  methods: {
    goToVenuePlanDetails(venue): void {
      if (venue.mock_value) return;

      this.$router.push({
        path: "/subscription-plan/details/" + venue.id,
        params: { id: venue.id },
      });
    },
    insertVenueImage(value: string): string {
      if (!value) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${value}`;
    },
  },
});
