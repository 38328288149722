





















import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "add-venue-list-item" as string,
  props: {
    onPressed: {
      type: Function as PropType<Function>,
      required: true,
    }
  },
})
